<template>
    <div>
                <div
                        :style="`flex:1 0 auto;padding-top:${
          landing.advanced.global.active_header_inside ||landing.advanced.global.active_logo_inside ? '20' : +landing.branding.topMenu.styles.size*2.5
        }px;padding-left:${
          landing.branding.lining_margin ? '15px' : '0'
        };padding-right:${landing.branding.lining_margin ? '15px' : '0'};`"
                >
                    <div
                            class="control"
                            :style="liningStyles"
                            style="min-height:300px; margin-bottom: 15px;"
                    >
                        <v-text-field @focus="showSearch" @click:append.stop="filter = true" prepend-inner-icon="mdi-magnify" append-icon="mdi-tune-variant" outlined background-color="#fff" rounded hide-details placeholder="Where to?">
                        </v-text-field>
                        <div style="display: flex;flex-direction: column;padding-top:15px;">
                            <div>Total stays: {{itemsCount}}</div>
                            <div v-if="!itemsCount" style="padding-top: 15px;font-weight: 600;font-size:20px;">
                                Sorry, no matching properties
                            </div>
                            <div style="padding: 10px 0" v-if="item.horizontal">
                                <v-slide-group
                                        class="slide_listing"
                                        v-model="active_listing_tab"
                                        center-active
                                >
                                    <v-slide-item
                                            v-for="(listingItem, index) in listings"
                                            :key="index"
                                            v-slot="{ active, toggle }"
                                    >
                                        <v-btn
                                                :input-value="active_listing_tab === index"
                                                outlined
                                                small
                                                depressed
                                                v-if="listingItem.title"
                                                class="mx-2"
                                                style="border: none; border-radius: 12px"
                                                :style="`background:${
                      active_listing_tab === index
                        ? item.styles.horizontal_active_background
                        : item.styles.horizontal_background
                    };color:${
                      active_listing_tab === index
                        ? item.styles.horizontal_active_color
                        : item.styles.horizontal_color
                    };`"
                                                @click="
                      toggle;
                      scrollToTitle(index);
                    "
                                        >
                                            {{ strip(listingItem.title) }}
                                        </v-btn>
                                    </v-slide-item>
                                </v-slide-group>
                            </div>
                            <div v-if="item.styles.listing_vector === 'horizontal'" style="overflow:hidden;">
                                <div
                                        style="margin-bottom: 15px; display: flex; flex-direction: column"
                                        v-for="(listingItem, index) in listings"
                                        :key="index"
                                >
                                    <div
                                            v-if="content.showGroup"
                                            :ref="`listing${index}`"
                                            :style="`color:${content.styles.title.color};font-size:${content.styles.title.fontSize}px;font-weight:${content.styles.title.fontWeight};font-style:${content.styles.title.fontStyle};font-family:${content.styles.title.fontFamily};`"
                                            style="height: auto; min-height: auto; padding: 5px"
                                            v-html="htmlData(listingItem.title)"
                                    ></div>
                                    <swiper
                                            ref="mySwiper"
                                            :options="swiperOptions"
                                    >

                                        <swiper-slide
                                                :id="`listing_${index}_${listingIndex}`"
                                                class="listing listing__item"
                                                v-for="(listing, listingIndex) in listingItem.content"
                                                :key="listingIndex"
                                                @click-slide="(sliderIndex) => openDetail(index,sliderIndex)"
                                                :style="
                      displayType(item.styles, listing) +
                      `border-radius:${item.styles.borderListingRadius}px;overflow:hidden;padding:0;min-height:${listingHeight};width:${content.styles.width}px;`
                    ">
                                            <div v-if="content.styles.show_favorite" style="position:absolute;top:15px;z-index:10;right:15px;cursor:pointer;" @click.stop="checkFavorite(listing)">
                                                <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false" :style="`fill:${listing.favorite?'#FF385C':'rgba(0, 0, 0, 0.5)'}`" style="display: block; height: 24px; width: 24px; stroke: #fff; stroke-width: 2; overflow: visible;"><path d="m16 28c7-4.733 14-10 14-17 0-1.792-.683-3.583-2.05-4.95-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05l-2.051 2.051-2.05-2.051c-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05-1.367 1.367-2.051 3.158-2.051 4.95 0 7 7 12.267 14 17z"></path></svg>
                                            </div>

                                            <div
                                                    :class="`preview__inside__${index}__${listingIndex}`"
                                                    v-if="listing.preview"
                                                    style="z-index: -1; position: absolute; width: 100%; height: 100%"
                                            ></div>

                                            <div
                                                    :class="`listing__item__img__${index}__${listingIndex}`"
                                                    v-if="listing.main_img && item.styles.displayType !== 'inside'"
                                                    style="float: left;"
                                                    :style="`overflow:hidden;width:100%;min-height:${
                        item.styles.listing_img_height
                      }px;overflow:hidden;`"
                                            >
                                                <img   @click="openDetail(index,listingIndex)"
                                                       :src="listing.main_img"
                                                       alt=""
                                                       :style="`height:${item.styles.listing_img_height}px;width:100%;`"
                                                       style="width: 100%"
                                                />
                                            </div>
                                            <div
                                                    v-if="content.styles.content_position === 'center'"
                                                    :style="`min-height:${content.styles.lining_height}px;width:100%;font-family:${content.styles.fontFamily};display:${
                        item.styles.displayType === 'inside' ? 'flex' : 'block'
                      };justify-content:center;align-items:center;`"
                                            >
                                                <div
                                                        :style="`height:100%;width:100%;border-radius:${
                          item.styles.borderListingRadius
                        }px;padding:${
                          item.styles.displayType === 'inside' ? content.styles.lining_padding+'px' : '0'
                        };background:${
                          item.styles.displayType === 'inside'
                            ? content.styles.lining_color
                            : 'transparent'
                        };padding:10px;`"
                                                >
                                                    <p v-if="content.styles.show_location"
                                                       class="listing__text"
                                                       style="margin-bottom: 10px;"
                                                       :style="`color:${listing.descr_color};font-family:${content.styles.fontFamilyDescription};font-size:${content.styles.descriptionFontSize}px;`"
                                                    >
                                                        <v-icon :size="content.styles.descriptionFontSize">mdi-map-marker</v-icon> {{listing.map.location}}
                                                    </p>
                                                    <p
                                                            class="listing__name"
                                                            style="margin-bottom: 10px;"
                                                            :style="`color:${listing.name_color};font-family:${content.styles.fontFamily};font-size:${content.styles.nameFontSize}px;`"
                                                    >
                                                        {{ listing.name }}
                                                    </p>
                                                    <p
                                                            class="listing__text"
                                                            style="margin-bottom: 10px;"
                                                            :style="`color:${listing.descr_color};font-family:${content.styles.fontFamilyDescription};font-size:${content.styles.descriptionFontSize}px;`"
                                                    >
                                                        <span v-if="content.styles.show_score_review"><v-icon color="orange" :size="content.styles.descriptionFontSize">mdi-star</v-icon> {{listing.reviews.score}}</span> <span v-if="content.styles.show_count_review">({{listing.reviews.count}} review)</span>
                                                    </p>

                                                    <div
                                                            class="listing__text"
                                                            style="margin-top: 10px"
                                                            :style="`display:flex;justify-content:space-evenly;align-items:flex-end;color:${listing.descr_color};font-family:${content.styles.fontFamilyDescription};font-size:${content.styles.descriptionFontSize}px;`"
                                                    >
                                                        <div style="display:flex;align-items:flex-end;"><v-icon style="margin-right: 10px;">mdi-account-group</v-icon><div>{{listing.options.max_guest}}</div></div>
                                                        <div style="display:flex;align-items:flex-end;"><v-icon style="margin-right: 10px;">mdi-bed</v-icon><div>{{listing.options.bed}}</div></div>
                                                        <div style="display:flex;align-items:flex-end;"><v-icon style="margin-right: 10px;">mdi-shower</v-icon><div>{{listing.options.bathroom}}</div></div>
                                                        <div style="display:flex;align-items:flex-end;"><v-icon style="margin-right: 10px;">mdi-arrow-expand-all</v-icon><div>{{listing.options.room_size}}</div></div>
                                                    </div>

                                                </div>
                                            </div>
                                        </swiper-slide>
                                    </swiper>
                                </div>

                            </div>
                            <div v-else>

                                <div
                                        class="draggable_group"
                                        style="margin-bottom: 15px; display: flex; flex-direction: column"
                                        v-for="(listingItem, index) in listings"
                                        :key="index"
                                >
                                    <div
                                            :ref="`listing${index}`"
                                            style="height: auto; min-height: auto;margin-bottom:15px;"
                                            v-html="htmlData(listingItem.title)"
                                    ></div>
                                    <div
                                            @click="openDetail(index,listingIndex)"
                                            v-for="(listing, listingIndex) in listingItem.content.filter(
              (x) => x.hide
            )"
                                            :key="listingIndex">
                                        <div
                                                v-if="content.styles.content_position === 'top' && item.styles.displayType === 'inside'"
                                                style="height: 100%"
                                                :style="`font-family:${content.styles.fontFamily};display:${
                item.styles.displayType === 'inside' ? 'flex' : 'block'
              };justify-content:center;align-items:center;text-align:${
                item.styles.displayType !== 'horizontal' ? 'center' : 'left'
              };`"
                                        >
                                            <div
                                                    :style="`border-radius:${
                  item.styles.borderListingRadius
                }px;padding:${
                  item.styles.displayType === 'inside' ? '15px' : '0'
                };background:${
                  item.styles.displayType === 'inside'
                    ? content.styles.lining_color
                    : 'transparent'
                };overflow:hidden;`"
                                            >
                                                <p
                                                        class="listing__name"
                                                        style="display:block;padding:0 15px;text-align:left;font-weight:500"
                                                        :style="`color:${listing.name_color};font-family:${content.styles.fontFamily};font-size:${content.styles.nameFontSize}px;`"
                                                >
                                                    {{ listing.name }}
                                                </p>
                                                <div
                                                        class="listing__text"
                                                        style="margin-top: 10px"
                                                        :style="`display:flex;justify-content:space-evenly;align-items:flex-end;color:${listing.descr_color};font-family:${content.styles.fontFamilyDescription};font-size:${content.styles.descriptionFontSize}px;`"
                                                >
                                                    <div style="display:flex;align-items:flex-end;"><v-icon style="margin-right: 10px;">mdi-account-group</v-icon><div>{{listing.options.max_guest}}</div></div>
                                                    <div style="display:flex;align-items:flex-end;"><v-icon style="margin-right: 10px;">mdi-bed</v-icon><div>{{listing.options.bed}}</div></div>
                                                    <div style="display:flex;align-items:flex-end;"><v-icon style="margin-right: 10px;">mdi-shower</v-icon><div>{{listing.options.bathroom}}</div></div>
                                                    <div style="display:flex;align-items:flex-end;"><v-icon style="margin-right: 10px;">mdi-arrow-expand-all</v-icon><div>{{listing.options.room_size}}</div></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                                :id="`listing_${index}_${listingIndex}`"
                                                class="listing listing__item"
                                                style="
                position:relative;
                  display: block;
                  margin-bottom: 15px;
                  padding: 5px;
                "
                                                :style="
              displayType(item.styles, listing) +
              `overflow:hidden;border-radius:${item.styles.borderListingRadius}px;padding:0;min-height:${listingHeight};box-shadow:0 ${content.styles.shadow_size}px 6px ${content.styles.shadow_color};
              border:${content.styles.border_size}px solid ${content.styles.border_color};`
            "
                                        >
                                            <div v-if="content.styles.show_favorite" style="position:absolute;top:15px;z-index:10;right:15px;cursor:pointer;" @click.stop="checkFavorite(listing)">
                                                <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false" :style="`fill:${listing.favorite?'#FF385C':'rgba(0, 0, 0, 0.5)'}`" style="display: block; height: 24px; width: 24px; stroke: #fff; stroke-width: 2; overflow: visible;"><path d="m16 28c7-4.733 14-10 14-17 0-1.792-.683-3.583-2.05-4.95-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05l-2.051 2.051-2.05-2.051c-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05-1.367 1.367-2.051 3.158-2.051 4.95 0 7 7 12.267 14 17z"></path></svg>
                                            </div>
                                            <div
                                                    :class="`preview__inside__${index}__${listingIndex}`"
                                                    v-if="listing.preview"
                                                    style="z-index: -1; position: absolute; width: 100%; height: 100%"
                                            ></div>
                                            <swiper
                                                    ref="mySwiper"
                                                    :direction="'horizontal'"
                                                    :pagination="{ clickable: true }"
                                                    :options="swiperOptionsListing"
                                                    style="width: 100%;margin-bottom:15px; overflow: hidden;position: relative"
                                                    :style="`height:${item.styles.listing_img_height}px;width:100%;`"
                                                    v-if="listing.img.length && item.styles.displayType !== 'inside'"
                                            >
                                                <swiper-slide v-for="img in listing.img" :key="img">
                                                    <img
                                                            :src="img"
                                                            alt=""
                                                            :style="`height:${item.styles.listing_img_height}px;width:100%;`"
                                                    />
                                                </swiper-slide>
                                                <div
                                                        class="swiper-pagination"
                                                        style="bottom: 10px"
                                                        slot="pagination"
                                                ></div>
                                            </swiper>

                                            <div
                                                    v-if="content.styles.content_position === 'center'&& item.styles.displayType === 'inside' || item.styles.displayType !== 'inside'"
                                                    :style="`height:${item.styles.displayType === 'inside'?content.styles.lining_height+'px':'100%'};width:${item.styles.displayType === 'inside'?content.styles.lining_width+'%':'100%'};font-family:${content.styles.fontFamily};
                  text-align:${
                    item.styles.displayType !== 'horizontal' ? 'center' : 'left'
                  };`"
                                            >
                                                <div
                                                        :style="checkBorderRadius()+`;display:${item.styles.displayType === 'inside'?'flex':'block'};align-items:center;justify-content:center;flex-direction:column;width:100%;height:100%;padding:${
                  item.styles.displayType === 'inside' ? content.styles.lining_padding+'px' : '0'
                };background:${
                  item.styles.displayType === 'inside'
                    ? content.styles.lining_color
                    : 'transparent'
                };`"
                                                >
                                                    <p
                                                            class="listing__name"
                                                            style="display:block;padding:0 15px;text-align:left;font-weight:500"
                                                            :style="`color:${listing.name_color};font-family:${content.styles.fontFamily};margin-bottom:0;font-size:${content.styles.nameFontSize}px;`"
                                                    >
                                                        {{ listing.name }}
                                                    </p>
                                                    <div style="padding:0 15px;font-size:16px;" :style="`color:${listing.name_color};display:flex;flex-direction:column;align-items:flex-start;justify-content:flex-start;`">
                                                        <span :style="`text-decoration:${listing.sale_price?'line-through':'none'}`">{{listing.price}} {{landing.advanced.global.currency}} <span v-if="!listing.sale_price">/ day</span></span>
                                                        <span v-if="listing.sale_price">{{listing.sale_price}} {{landing.advanced.global.currency}} / day</span>
                                                    </div>
                                                    <div style="width: calc(100% - 30px);border-top:1px solid rgb(232,232,232);margin:15px;">

                                                    </div>
                                                    <div
                                                            class="listing__text"
                                                            style="margin-top: 10px;margin-bottom: 15px;"
                                                            :style="`display:flex;justify-content:space-evenly;align-items:flex-end;color:${listing.descr_color};font-family:${content.styles.fontFamilyDescription};font-size:${content.styles.descriptionFontSize}px;`"
                                                    >
                                                        <div style="display:flex;align-items:flex-end;"><v-icon style="margin-right: 10px;">mdi-account-group</v-icon><div>{{listing.options.max_guest}}</div></div>
                                                        <div style="display:flex;align-items:flex-end;"><v-icon style="margin-right: 10px;">mdi-bed</v-icon><div>{{listing.options.bed}}</div></div>
                                                        <div style="display:flex;align-items:flex-end;"><v-icon style="margin-right: 10px;">mdi-shower</v-icon><div>{{listing.options.bathroom}}</div></div>
                                                        <div style="display:flex;align-items:flex-end;"><v-icon style="margin-right: 10px;">mdi-arrow-expand-all</v-icon><div>{{listing.options.room_size}} m <sup>2</sup></div></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                                v-if="content.styles.content_position === 'bottom'&& item.styles.displayType === 'inside'"
                                                style="height: 100%"
                                                :style="`font-family:${content.styles.fontFamily};display:${
                item.styles.displayType === 'inside' ? 'flex' : 'block'
              };justify-content:center;align-items:center;text-align:${
                item.styles.displayType !== 'horizontal' ? 'center' : 'left'
              };`"
                                        >
                                            <div
                                                    :style="`overflow:hidden;border-radius:${
                  item.styles.borderListingRadius
                }px;padding:${
                  item.styles.displayType === 'inside' ? '15px' : '0'
                };background:${
                  item.styles.displayType === 'inside'
                    ? content.styles.lining_color
                    : 'transparent'
                };`"
                                            >
                                                <p
                                                        class="listing__name"
                                                        style="font-weight:500"
                                                        :style="`color:${listing.name_color};font-family:${content.styles.fontFamily};font-size:${content.styles.nameFontSize}px;`"
                                                >
                                                    {{ listing.name }}
                                                </p>
                                                <div
                                                        class="listing__text"
                                                        style="margin-top: 10px"
                                                        :style="`display:flex;justify-content:space-evenly;align-items:flex-end;color:${listing.descr_color};font-family:${content.styles.fontFamilyDescription};font-size:${content.styles.descriptionFontSize}px;`"
                                                >
                                                    <div style="display:flex;align-items:flex-end;"><v-icon style="margin-right: 10px;">mdi-account-group</v-icon><div>{{listing.options.max_guest}}</div></div>
                                                    <div style="display:flex;align-items:flex-end;"><v-icon style="margin-right: 10px;">mdi-bed</v-icon><div>{{listing.options.bed}}</div></div>
                                                    <div style="display:flex;align-items:flex-end;"><v-icon style="margin-right: 10px;">mdi-shower</v-icon><div>{{listing.options.bathroom}}</div></div>
                                                    <div style="display:flex;align-items:flex-end;"><v-icon style="margin-right: 10px;">mdi-arrow-expand-all</v-icon><div>{{listing.options.room_size}}</div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        </div>
        <transition name="fade">
            <div v-if="search" style="padding:15px 15px 60px 15px;position: absolute;left:0;z-index:99;width:360px;height:640px;background-color:#f7f7f7;top:0;overflow:auto;">
                <v-btn style="height:30px;width:30px;border:1px solid rgb(176, 176, 176);background:rgba(255,255,255,.9);margin-bottom: 15px;" @click="search = false" small rounded icon><v-icon small color="#222222">mdi-window-close</v-icon></v-btn>
                <div style="background-color: #fff;border-radius: 16px;padding:15px;margin-bottom: 15px">
                    <div style="font-size: 22px;font-weight: 800;line-height: 26px;margin-bottom: 15px;">Where to?</div>
                    <v-autocomplete outlined prepend-inner-icon="mdi-magnify"
                                    no-data-text="Not found" :items="cities" v-model="searchLocation"
                                    item-value="id" @change="changeLocation"
                                    item-text="name" clearable return-object append-icon="" placeholder="Search destinations"></v-autocomplete>
                </div>

                <div style="position:absolute;bottom: 0;left:0;height:60px;padding:0 15px;width:100%;background-color: #fff;display:flex;align-items: center;justify-content: space-between">
                    <div style="cursor:pointer;color:#222;text-decoration: underline;font-size: 16px;font-weight: 600;" @click="clearSearch">
                        Clear all
                    </div>
                    <div>
                        <v-btn color="primary" outlined small @click="search = false">Show ({{itemsCount}} stays)</v-btn>
                    </div>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div v-if="filter" style="position: absolute;left:0;z-index:99;width:360px;height:640px;background-color:#f7f7f7;top:0;overflow:auto;">
                <div style="padding:15px 15px 60px 15px;">
                    <v-btn style="height:30px;width:30px;border:1px solid rgb(176, 176, 176);background:rgba(255,255,255,.9);margin-bottom: 15px;" @click="filter = false" small rounded icon><v-icon small color="#222222">mdi-window-close</v-icon></v-btn>
                    <div style="background-color: #fff;border-radius: 16px;padding:15px;margin-bottom: 15px;overflow: hidden">
                        <div style="font-size: 22px;font-weight: 800;line-height: 26px;margin-bottom: 5px;">Rooms and beds</div>
                        <div style="border-bottom: 1px solid rgb(235, 235, 235);padding-bottom:15px;margin-bottom:15px;display: flex;flex-direction: column;">
                            <div style="font-size: 16px !important;
                                            line-height: 20px !important;
                                            font-weight: 600 !important;margin-bottom: 15px;">
                                Bedrooms</div>
                            <swiper
                                    ref="mySwiper"
                                    :options="swiperOptions" @click-slide="changeBedrooms"
                            >
                                <swiper-slide :style="`background:${bedroomsCount === 'Any'?'#000':'#fff'};color:${bedroomsCount === 'Any'?'#fff':'#000'};`"  style="cursor: pointer !important;
                                text-align: center !important;
                                border: 1px solid rgb(34, 34, 34) !important;
                                outline: none !important;
                                margin: 0px !important;
                                font-family: Circular, sans-serif !important;
                                position: relative !important;
                                transition-property: -ms-transform, -webkit-transform, transform, background-color, border-color !important;
                                transition-duration: 0.15s !important;
                                transition-timing-function: ease-in-out !important;
                                padding: 10px 20px !important;
                                border-radius: 30px !important;
                                font-size: 12px !important;
                                line-height: 16px !important;
                                width: 88px !important;
                                background-color: rgb(34, 34, 34) !important;
                                color: rgb(255, 255, 255) !important;
                                font-weight: 800 !important;">
                                    <div style="    font-weight: 400 !important;
                                font-size: 14px !important;
                                line-height: 18px !important;
                                padding: 0px 6px !important;">Any</div>
                                </swiper-slide>
                                <swiper-slide v-for="index in 8" :key="index" :style="`background:${bedroomsCount === index?'#000':'#fff'};color:${bedroomsCount === index?'#fff':'#000'};`"   style="cursor: pointer !important;
                                text-align: center !important;
                                border: 1px solid rgb(34, 34, 34) !important;
                                outline: none !important;
                                margin: 0px !important;
                                font-family: Circular, sans-serif !important;
                                position: relative !important;
                                transition-property: -ms-transform, -webkit-transform, transform, background-color, border-color !important;
                                transition-duration: 0.15s !important;
                                transition-timing-function: ease-in-out !important;
                                padding: 10px 20px !important;
                                border-radius: 30px !important;
                                font-size: 12px !important;
                                line-height: 16px !important;
                                width: 68px !important;
                                background-color: rgb(34, 34, 34) !important;
                                color: rgb(255, 255, 255) !important;
                                font-weight: 800 !important;">
                                    <div style="    font-weight: 400 !important;
                                font-size: 14px !important;
                                line-height: 18px !important;
                                padding: 0px 6px !important;">{{index===8?'8+':index}}</div>
                                </swiper-slide>
                            </swiper>
                        </div>
                        <div style="border-bottom: 1px solid rgb(235, 235, 235);padding-bottom:15px;margin-bottom:15px;display: flex;flex-direction: column;">
                            <div style="font-size: 16px !important;
                                            line-height: 20px !important;
                                            font-weight: 600 !important;margin-bottom: 15px;">
                                Beds</div>
                            <swiper
                                    ref="mySwiper"
                                    :options="swiperOptions" @click-slide="changeBeds"
                            >
                                <swiper-slide :style="`background:${bedsCount === 'Any'?'#000':'#fff'};color:${bedsCount === 'Any'?'#fff':'#000'};`"  style="cursor: pointer !important;
                                text-align: center !important;
                                border: 1px solid rgb(34, 34, 34) !important;
                                outline: none !important;
                                margin: 0px !important;
                                font-family: Circular, sans-serif !important;
                                position: relative !important;
                                transition-property: -ms-transform, -webkit-transform, transform, background-color, border-color !important;
                                transition-duration: 0.15s !important;
                                transition-timing-function: ease-in-out !important;
                                padding: 10px 20px !important;
                                border-radius: 30px !important;
                                font-size: 12px !important;
                                line-height: 16px !important;
                                width: 88px !important;
                                background-color: rgb(34, 34, 34) !important;
                                color: rgb(255, 255, 255) !important;
                                font-weight: 800 !important;">
                                    <div style="    font-weight: 400 !important;
                                font-size: 14px !important;
                                line-height: 18px !important;
                                padding: 0px 6px !important;">Any</div>
                                </swiper-slide>
                                <swiper-slide v-for="index in 8" :key="index" :style="`background:${bedsCount === index?'#000':'#fff'};color:${bedsCount === index?'#fff':'#000'};`"   style="cursor: pointer !important;
                                text-align: center !important;
                                border: 1px solid rgb(34, 34, 34) !important;
                                outline: none !important;
                                margin: 0px !important;
                                font-family: Circular, sans-serif !important;
                                position: relative !important;
                                transition-property: -ms-transform, -webkit-transform, transform, background-color, border-color !important;
                                transition-duration: 0.15s !important;
                                transition-timing-function: ease-in-out !important;
                                padding: 10px 20px !important;
                                border-radius: 30px !important;
                                font-size: 12px !important;
                                line-height: 16px !important;
                                width: 68px !important;
                                background-color: rgb(34, 34, 34) !important;
                                color: rgb(255, 255, 255) !important;
                                font-weight: 800 !important;">
                                    <div style="    font-weight: 400 !important;
                                font-size: 14px !important;
                                line-height: 18px !important;
                                padding: 0px 6px !important;">{{index===8?'8+':index}}</div>
                                </swiper-slide>
                            </swiper>
                        </div>
                        <div style="border-bottom: 1px solid rgb(235, 235, 235);padding-bottom:15px;margin-bottom:15px;display: flex;flex-direction: column;">
                            <div style="font-size: 16px !important;
                                            line-height: 20px !important;
                                            font-weight: 600 !important;margin-bottom: 15px;">
                                Bathrooms</div>
                            <swiper
                                    ref="mySwiper"
                                    :options="swiperOptions" @click-slide="changeBathrooms"
                            >
                                <swiper-slide :style="`background:${bathroomsCount === 'Any'?'#000':'#fff'};color:${bathroomsCount === 'Any'?'#fff':'#000'};`"  style="cursor: pointer !important;
                                text-align: center !important;
                                border: 1px solid rgb(34, 34, 34) !important;
                                outline: none !important;
                                margin: 0px !important;
                                font-family: Circular, sans-serif !important;
                                position: relative !important;
                                transition-property: -ms-transform, -webkit-transform, transform, background-color, border-color !important;
                                transition-duration: 0.15s !important;
                                transition-timing-function: ease-in-out !important;
                                padding: 10px 20px !important;
                                border-radius: 30px !important;
                                font-size: 12px !important;
                                line-height: 16px !important;
                                width: 88px !important;
                                background-color: rgb(34, 34, 34) !important;
                                color: rgb(255, 255, 255) !important;
                                font-weight: 800 !important;">
                                    <div style="    font-weight: 400 !important;
                                font-size: 14px !important;
                                line-height: 18px !important;
                                padding: 0px 6px !important;">Any</div>
                                </swiper-slide>
                                <swiper-slide v-for="index in 8" :key="index" :style="`background:${bathroomsCount === index?'#000':'#fff'};color:${bathroomsCount === index?'#fff':'#000'};`"   style="cursor: pointer !important;
                                text-align: center !important;
                                border: 1px solid rgb(34, 34, 34) !important;
                                outline: none !important;
                                margin: 0px !important;
                                font-family: Circular, sans-serif !important;
                                position: relative !important;
                                transition-property: -ms-transform, -webkit-transform, transform, background-color, border-color !important;
                                transition-duration: 0.15s !important;
                                transition-timing-function: ease-in-out !important;
                                padding: 10px 20px !important;
                                border-radius: 30px !important;
                                font-size: 12px !important;
                                line-height: 16px !important;
                                width: 68px !important;
                                background-color: rgb(34, 34, 34) !important;
                                color: rgb(255, 255, 255) !important;
                                font-weight: 800 !important;">
                                    <div style="    font-weight: 400 !important;
                                font-size: 14px !important;
                                line-height: 18px !important;
                                padding: 0px 6px !important;">{{index===8?'8+':index}}</div>
                                </swiper-slide>
                            </swiper>
                        </div>
                    </div>
                    <div style="background-color: #fff;border-radius: 16px;padding:15px;margin-bottom: 15px;overflow: hidden">
                        <div style="font-size: 22px;font-weight: 800;line-height: 26px;margin-bottom: 15px;">Property type</div>
                        <div style="    display: grid !important;
        grid-template-columns: repeat(2, minmax(0px, 1fr)) !important;
        grid-template-rows: repeat(2, auto) !important;
        gap: 16px !important;">

                            <div @click="mainStay.active = !mainStay.active" :style="`background-color: ${mainStay.active?'rgb(235, 235, 235) !important;':'rgb(255, 255, 255) !important;'}border: ${mainStay.active?'2px solid rgb(0,0,0) !important;':'1px solid rgba(176, 176, 176, 0.5) !important;'}`" style="border-radius: 16px !important;-webkit-box-pack: justify !important;
                            -webkit-box-direction: normal !important;
                            -webkit-box-orient: vertical !important;
                            padding: 16px !important;
                            display: flex !important;
                            flex-direction: column !important;
                            justify-content: space-between !important;
                            align-items: flex-start;
                            color: rgb(34, 34, 34) !important;
                            min-height: 120px !important;
                            height: 100% !important;
                            width: 100% !important;flex:1;" v-for="mainStay in main_stays_filtered" :key="mainStay.name">
                                <v-icon size="32px">{{mainStay.icon}}</v-icon>
                                <div style="    font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 20px !important;
        text-align: left !important;
        padding-top: 24px !important;
        white-space: normal !important;">
                                    {{mainStay.name}}
                                </div>
                            </div>
                        </div>
                        <div style="padding:15px 0;">
                            <div @click="show_all_main_stays = !show_all_main_stays" style="color:#222;text-decoration: underline;font-size: 16px;font-weight: 600;">
                                {{show_all_main_stays?'Hide ':'Show all '}} stays
                            </div>
                        </div>
                    </div>
                    <div style="background-color: #fff;border-radius: 16px;padding:15px;margin-bottom: 15px">
                        <div style="font-size: 22px;font-weight: 800;line-height: 26px;margin-bottom: 15px;">Accessibility features</div>
                        <div style="    color: rgb(113, 113, 113) !important;
        font-size: 16px !important;
        line-height: 20px !important;margin-bottom: 15px;">This info was provided by the Host and reviewed by Tourist.hr</div>

                        <div style="border-bottom: 1px solid rgb(235, 235, 235);display: flex;justify-content: space-between;align-items:center;" v-for="feature in accessibilityFeaturesFiltered" :key="feature.name">
                            <div style="display: flex;flex-direction: column;">
                                <div style="font-size: 16px !important;
                                            line-height: 20px !important;
                                            font-weight: 600 !important;">{{feature.name}}</div>
                            </div>
                            <div style="display: flex;align-items: center;justify-content: center">
                                <v-checkbox v-model="feature.active"></v-checkbox>
                            </div>
                        </div>
                        <div style="padding:15px 0;">
                            <div @click="show_all_feature = !show_all_feature" style="color:#222;text-decoration: underline;font-size: 16px;font-weight: 600;">
                                {{show_all_feature?'Hide ':'Show all '}} features
                            </div>
                        </div>
                    </div>
                    <div style="background-color: #fff;border-radius: 16px;padding:15px;margin-bottom: 15px">
                        <div style="font-size: 22px;font-weight: 800;line-height: 26px;margin-bottom: 15px;">House rules</div>
                        <div :style="`${index !== houseRules.length-1?'border-bottom: 1px solid rgb(235, 235, 235)':'border:none'};`" style="display: flex;justify-content: space-between;align-items:center;" v-for="(rule,index) in houseRules" :key="rule.name">
                            <div style="display: flex;flex-direction: column;">
                                <div style="font-size: 16px !important;
                                            line-height: 20px !important;
                                            font-weight: 600 !important;">{{rule.name}}</div>
                            </div>
                            <div style="display: flex;align-items: center;justify-content: center">
                                <v-checkbox v-model="rule.active"></v-checkbox>
                            </div>
                        </div>
                    </div>
                    <div style="background-color: #fff;border-radius: 16px;padding:15px;margin-bottom: 15px">
                        <div style="font-size: 22px;font-weight: 800;line-height: 26px;margin-bottom: 15px;">Facilities</div>
                        <div :style="`${index !== facilities.length-1?'border-bottom: 1px solid rgb(235, 235, 235)':'border:none'};`" style="display: flex;justify-content: space-between;align-items:center;" v-for="(property,index) in facilities" :key="property.name">
                            <div style="display: flex;flex-direction: column;">
                                <div style="font-size: 16px !important;
                                            line-height: 20px !important;
                                            font-weight: 600 !important;">{{property.name}}</div>
                            </div>
                            <div style="display: flex;align-items: center;justify-content: center">
                                <v-checkbox v-model="property.active"></v-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="position:sticky;left:0;z-index:100;bottom: 0;height:60px;padding:0 15px;width:100%;background-color: #fff;display:flex;align-items: center;justify-content: space-between">
                    <div style="color:#222;cursor:pointer;text-decoration: underline;font-size: 16px;font-weight: 600;" @click="setDefaultListing">
                        Clear all
                    </div>
                    <div>
                        <v-btn color="primary" outlined small @click="filter = false">Show {{itemsCount}} stays</v-btn>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
    import {mapState} from 'vuex';
    import SwiperCore, {
      Pagination
    } from 'swiper';
    import { Swiper, SwiperSlide} from 'vue-awesome-swiper'
    SwiperCore.use([Pagination]);
    export default{
      name:"typeRealEstateListPreview",
      props:['content','item'],
      components:{
        Swiper,
        SwiperSlide
      },
      data(){
        return{
          cities:[],
          searchLocation:"",
          listing_item:{
            min_day_stays:1
          },
          nextClicked:false,
          week_days:['Su','Mo','Tu','We','Th','Fr','Sa'],
          reverse_slide:false,
          selectedDates:[],
          disableDates:[],
          dates:[],
          active_month:"",
          swiperOptionsListing: {
            grabCursor: true,
            centeredSlides: true,
            pagination: {
              el: ".swiper-pagination",
              type: "bullets",
              clickable: true,
            },
          },
          mapStyle: {
            disableDefaultUi: true,
            zoomControl: true,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: true,
            styles: [
              {
                featureType: 'poi',
                stylers: [{visibility: "off"}]
              },
            ]
          },
          priceRange:0,
          houseRules:[
            {
              name:"Pets allowed",
              active:false,
            },
            {
              name:"Smoking allowed",
              active:false,
            }
          ],
          center:{
            lat:0,
            lng:0
          },
          step:5,
          mapIcons:null,
          show_all_feature:false,
          active_listing_tab: 0,
          show_all_main_stays:false,
          main_stays_active:[],
          main_stays:[
            {
              name:"House",
              icon:"mdi-home",
              active:false,
            },
            {
              name:"Flat",
              icon:"mdi-home-city",
              active:false,
            },
            {
              name:"Apartament",
              icon:"mdi-home-group",
              active:false,
            },
            {
              name:"Bunglow",
              icon:"mdi-warehouse",
              active:false,
            },
            {
              name:"Chalet",
              icon:"mdi-warehouse",
              active:false,
            },
            {
              name:"Townhouse",
              icon:"mdi-warehouse",
              active:false,
            },
            {
              name:"Villa",
              icon:"mdi-warehouse",
              active:false,
            },
            {
              name:"Guest house",
              icon:"mdi-warehouse",
              active:false,
            },
            {
              name:"Loft",
              icon:"mdi-warehouse",
              active:false,
            },
            {
              name:"Guest suite",
              icon:"mdi-warehouse",
              active:false,
            },
            {
              name:"Cottage",
              icon:"mdi-warehouse",
              active:false,
            },
            {
              name:"Hostel",
              icon:"mdi-warehouse",
              active:false,
            },
            {
              name:"Cabin",
              icon:"mdi-warehouse",
              active:false,
            },

          ],
          bedroomsCount:"Any",
          bedsCount:"Any",
          bathroomsCount:"Any",
          personsTypes:[
            {
              name:"Adults",
              subname:"Ages 13 or above",
              count:0
            },
            {
              name:"Children",
              subname:"Ages 2-12",
              count:0
            },
            {
              name:"Infants",
              subname:"Under 2",
              count:0
            },
            {
              name:"Pets",
              subname:"Service animals",
              count:0
            }
          ],
          facilities:[
            {
              name:"Free parking on premises",
              active:false,
            },
            {
              name:"Gym",
              active:false,
            },
            {
              name:"Hot tub",
              active:false,
            },
            {
              name:"Pool",
              active:false,
            },
            {
              name:"EV charger",
              active:false,
            }
          ],
          accessibilityFeatures:[
            {
              name:"Kitchen",
              active:false,
            },
            {
              name:"Air conditioning",
              active:false,
            },
            {
              name:"Washing machine",
              active:false,
            },
            {
              name:"Wifi",
              active:false,
            },
            {
              name:"Indoor fireplace",
              active:false,
            },
            {
              name:"Hair dryer",
              active:false,
            },
            {
              name:"TV",
              active:false,
            },
            {
              name:"High chair",
              active:false,
            },
            {
              name:"Private bathroom",
              active:false,
            },
            {
              name:"Beachfront",
              active:false,
            },
            {
              name:"Heating",
              active:false,
            },
            {
              name:"Dryer",
              active:false,
            },
            {
              name:"Breakfast",
              active:false,
            },
            {
              name:"Iron",
              active:false,
            },
            {
              name:"Dedicated workspace",
              active:false,
            },
            {
              name:"Cot",
              active:false,
            },
            {
              name:"Self check-in",
              active:false,
            },
            {
              name:"Ski-in/ski-out",
              active:false,
            },
            {
              name:"Waterfront",
              active:false,
            },
          ],
          liningStyles:null,
          flex:true,
          filter:false,
          who:false,
          loaded:false,
          hotelStyle:null,
          search:false,
          datesShow:true,
          listings:[],
          listingsCopy:[],
          monthFromNow:0,
          activeDate:null,
          archiveDates:[],
          archiveStartDates:[],
          disabledDates:[],
          ignoredDates:[],
          activeValueFlexible:'Week',
          activeValueFlexibleMonth:[],
          swiperOptions: {
            slidesPerView: "auto",
            spaceBetween: 15,
          },
          flexibleTypes:[
            'Weekend',
            'Week',
            'Month'
          ],
          placesTypes:[
            {
              name:"Entire place",
              subname:"A place all to yourself",
              active:false,
            },
            {
              name:"Private room",
              subname:"Your own room in a home or a hotel, plus some shared common spaces",
              active:false,
            },
            {
              name:"Shared room",
              subname:"A sleeping space and common areas that may be shared with others",
              active:false,
            },
          ],
          monthsShort:{
            January:'Jan',
            February:'Feb',
            March:'Mar',
            April:'Apr',
            May:'May',
            June:'Jun',
            July:'Jul',
            August:'Aug',
            September:'Sept',
            October:'Oct',
            November:'Nov',
            December:'Dec',
          }
        }
      },
      computed:{
        ...mapState(['landing']),
        ...mapState('touristSpace',['touristSpaces']),
        showDates(){
          if(this.selectedDates.length){
            return `${this.$moment(this.selectedDates[0].start).format('MMM DD')} - ${this.$moment(this.selectedDates[this.selectedDates.length-1].start).format('MMM DD')}`
          }
          if(this.activeValueFlexible&&this.activeValueFlexibleMonth.length){
            return this.activeValueFlexible + ' in '+ this.activeValueFlexibleMonthShort
          }
          return 'Add dates';
        },
        listingsPoints(){
          return this.listings.reduce((acc,item,index) => {
            for(let i = 0;i<item.content.length;i++){
              item.content[i].group_index = index;
              item.content[i].item_index = i;
              acc.push(item.content[i]);
            }
            return acc;
          },[]);
        },
        tickLabels(){
          return [`${this.minPrice}`,...Array.from({ length: this.maxPrice/5 - 2 }),`${this.maxPrice}`]
        },
        minPrice(){
          const priceArr = [];
          for(let i = 0;i<this.listings.length;i++){
            for(let index = 0;index<this.listings[i].content.length;index++){
              if(this.listings[i].content[index].price){
                priceArr.push(this.listings[i].content[index].price);
              }
            }
          }
          if(priceArr.length === 1 || !priceArr.length){
            return 0
          }else{
            priceArr.sort((a,b) => +a - +b);
            return +priceArr[0];
          }
        },
        maxPrice(){
          const priceArr = [];
          for(let i = 0;i<this.listings.length;i++){
            for(let index = 0;index<this.listings[i].content.length;index++){
              if(this.listings[i].content[index].price){
                priceArr.push(this.listings[i].content[index].price);
              }
            }
          }
          if(priceArr.length === 1){
            return +priceArr[0];
          } else if(!priceArr.length){
            return 1000
          }
          else{
            priceArr.sort((a,b) => +b - +a);
            return +priceArr[0];
          }
        },
        accessibilityFeaturesFiltered(){
          return this.show_all_feature ? this.accessibilityFeatures : JSON.parse(JSON.stringify(this.accessibilityFeatures)).splice(0,4);
        },
        main_stays_filtered(){
          return this.show_all_main_stays ? this.main_stays : JSON.parse(JSON.stringify(this.main_stays)).splice(0,4);
        },
        itemsCount:function(){
          return this.listings.reduce((acc,item) => {
            acc+=item.content.length;
            return acc;
          },0)
        },
        listingHeight: function () {
          if (this.item.styles.displayType === "inside") {
            return `${this.content.styles.height}px`;
          } else if (this.item.styles.displayType === "horizontal") {
            return `${+this.item.styles.listing_img_height + 10}px`;
          } else {
            return `${this.content.styles.height}px`;
          }
        },
        activeValueFlexibleMonthShort(){
          return this.activeValueFlexibleMonth.reduce((acc,item,index) => {
            if(index === 5){
              acc+= '...'
            }
            if(index <= 4){
              acc += this.monthsShort[item]
              if(index!== this.activeValueFlexibleMonth.length-1){
                acc+= ', '
              }
            }
            return acc
          },'');
        },
        months(){

          let today = this.$moment();
          const dates = [];
          for(let i = 0;i<=11;i++){
            const date = {
              name:i?this.$moment(today).add(i,'month').format('MMMM'):this.$moment(today).format('MMMM'),
              year:i?this.$moment(today).add(i,'month').format('YYYY'):this.$moment(today).format('YYYY'),
            };
            dates.push(date);
          }
          return dates;

        }
      },
      watch:{
        search:function(val){
          if(val){
            let canvas = document.getElementsByClassName('canvas')[0];
            canvas.scrollTo(0,0);
            canvas.style.overflow = 'hidden';
          }else{
            let canvas = document.getElementsByClassName('canvas')[0];
            canvas.scrollTo(0,0);
            setTimeout(() => {
              canvas.style.overflow = 'auto';
            },500);
          }
        },
        filter:function(val){
          if(val){
            let canvas = document.getElementsByClassName('canvas')[0];
            canvas.scrollTo(0,0);
            canvas.style.overflow = 'hidden';
          }else{
            let canvas = document.getElementsByClassName('canvas')[0];
            canvas.scrollTo(0,0);
            setTimeout(() => {
              canvas.style.overflow = 'auto';
            },500);
          }
        },
        "$store.state.realEstate.realEstates":function(val){
          console.log(val);
          this.changeDataModel(val);
        }
      },
      methods:{
        filterItems(){
          for(let i = 0;i<this.listings.length;i++){
            for(let index = 0;index<this.listings[i].content.length;index++){
            //
            }
          }
        },
        changeLocation(){
          const arr = this.$store.state.realEstate.realEstates.reduce((acc,item) => {
            if(item.city === this.searchLocation || !this.searchLocation){
              acc.push(item)
            }
            return acc;
          },[]);
          this.changeDataModel(arr);
        },
        clearSearch(){
          this.searchLocation = "";
          this.changeLocation();
        },
        async searchSpaces(){
          const payload = {
            start_date:"",
            end_date:"",
            persons:"",
            price_start:0,
            price_end:800
          };
          if(this.selectedDates.length){
            payload.start_date = this.selectedDates[0].start;
            payload.end_date = this.selectedDates[this.selectedDates.length-1].start;
          }else
          if(this.activeValueFlexibleMonth){
            console.log(this.activeValueFlexibleMonth);
          }else{
            payload.start_date = this.$this.$moment().startOf('month').format('YYYY-MM-DD');
            payload.end_date = this.$this.$moment().endOf('month').format('YYYY-MM-DD');
          }
          for(let i = 0;i<this.personsTypes.length;i++){
            if(this.personsTypes[i].count){
              if(payload.persons){
                payload.persons += `&${this.personsTypes[i].name.toLowerCase()}=${this.personsTypes[i].count}`;
              }else{
                payload.persons += `${this.personsTypes[i].name.toLowerCase()}=${this.personsTypes[i].count}`;
              }
            }
          }
          // await this.getFilteredSpaces(payload);
          this.search = false;
        },
        clickNext(){
          this.flex = false;
          this.who = true;
          this.nextClicked = true;
        },
        setMonth(indexMonth){
          const month = this.months[indexMonth].name;
          if(this.activeValueFlexibleMonth.includes(month)){
            this.activeValueFlexibleMonth.splice(this.activeValueFlexibleMonth.indexOf(month),1)
          }else{
            this.activeValueFlexibleMonth.push(month)
          }
        },
        scrollToTitle(index) {
          this.active_listing_tab = index;
          document.documentElement.style.scrollBehavior = "smooth";
          if (index === 0) {
            document.documentElement.scrollTop = 0;
          } else {
            document.documentElement.scrollTop =
              this.$refs[`listing${index}`][0].offsetTop - 50;
          }
        },
        htmlData(data, type) {
          if (data) {
            const weatherInd = data.split(" ").indexOf(":weather:");
            let result = data.split(" ");
            if (weatherInd && this.landing.advanced.global.show_weather) {
              const newArr = result;
              newArr[weatherInd] =
                this.landing.advanced.global.weather.week.daily[0].temp.day.toFixed(
                  0
                ) + " ℃";
              result = newArr;
            }
            const timeInd = data.split(" ").indexOf(":time:");
            if (timeInd) {
              const newArr = result;
              newArr[timeInd] = this.datenow;
              result = newArr;
            }
            const dateInd = data.split(" ").indexOf(":date:");
            if (dateInd) {
              const newArr = result;
              newArr[dateInd] = this.dateToday;
              result = newArr;
            }
            const user_name = data.split(" ").indexOf(":name:");
            if (
              user_name &&
              this.landing.advanced.global.auth &&
              this.landing.advanced.auth.name &&
              type === "title"
            ) {
              const newArr = result;
              newArr[user_name] = "Agent";
              result = newArr;
            }
            const user_surname = data.split(" ").indexOf(":surname:");
            if (
              user_surname &&
              this.landing.advanced.global.auth &&
              this.landing.advanced.auth.name &&
              type === "title"
            ) {
              const newArr = result;
              newArr[user_surname] = "Smith";
              result = newArr;
            }
            const user_birthday = data.split(" ").indexOf(":birthday:");
            if (
              user_surname &&
              this.landing.advanced.global.auth &&
              this.landing.advanced.auth.name &&
              type === "title"
            ) {
              const newArr = result;
              newArr[user_birthday] = "24.03.1999";
              result = newArr;
            }
            const user_room = data.split(" ").indexOf(":room:");
            if (
              user_surname &&
              this.landing.advanced.global.auth &&
              this.landing.advanced.auth.name &&
              type === "title"
            ) {
              const newArr = result;
              newArr[user_room] = "707";
              result = newArr;
            }

            const user_auth = data.split(" ").indexOf(":auth:");
            if (user_auth && this.landing.advanced.global.auth && !type) {
              const newArr = result;
              newArr[user_auth] = `<a href="#">Login</a>`;
              result = newArr;
            }
            const user_logout = data.split(" ").indexOf(":logout:");
            if (user_logout && this.landing.advanced.global.auth && !type) {
              const newArr = result;
              newArr[user_logout] = `<a href="#">Logout</a>`;
              result = newArr;
            }
            return result.join(" ");
          }
        },
        async goToBack() {
          await this.$router.push(
            `/${+this.$route.params.id}`
          );
        },
        changeDataModel(data){
          this.listings = [];
          const filteredArr = data.reduce((acc,item) => {
               let trigger = false;
               if(this.bathroomsCount === 'Any' &&
               this.bedsCount === 'Any' &&
               this.bedroomsCount === 'Any' &&
               !this.searchLocation)
               {
                 trigger = true;
               }
              if(this.bathroomsCount !== 'Any' && item.wc_count === this.bathroomsCount){
                trigger = true;
              }
              if(this.bedsCount !== 'Any'&&item.beds_count === this.bedsCount){
                trigger = true;
              }
              if(this.bedroomsCount !== 'Any'&&item.rooms_count === this.bedroomsCount){
                trigger = true;
              }
                if(this.searchLocation&&item.city === this.searchLocation){
                  trigger = true;
                }
                if(trigger){
                  acc.push(item);
                }
              return acc
          },[]);
          for(let i =0;i<filteredArr.length;i++) {
            let item = filteredArr[i];
            if(item.city){
              this.cities.push(item.city);
            }
            let defaultListingGroup = {
              title: item.category,
              hide: false,
              content: [
                {
                  options:{
                    bed:item.beds_count,
                    bathroom:item.wc_count,
                    room_size:item.room_size,
                    max_guest:item.max_guest
                  },
                  map: {
                    lng: item.coordinates.lng,
                    lat: item.coordinates.lat,
                    location: item.city
                  },
                  reviews: {
                    count: item.review_count?item.review_count:0,
                    score: item.review_score?item.review_score:0
                  },
                  price:item.price,
                  sale_price:item.sale_price&&+item.sale_price!==+item.price?item.sale_price:null,
                  address: item.address,
                  detail_link: "",
                  img: item.images.length ? item.images : ['https://tourist.hr/images/default/item.jpg'],
                  video_link: item.video_link,
                  name: item.name[this.landing.advanced.default_lang],
                  descr: "",
                  main_img: item.main_image ? item.main_image : 'https://tourist.hr/images/default/item.jpg',
                  preview: false,
                  full_descr: item.description[this.landing.advanced.default_lang],
                  hide: true,
                  show_price: true,
                  show_descr: true,
                  name_color: "#000000",
                  favorite: false,
                  position: "center center",
                  background_color: "rgba(255,255,255,1)",
                  descr_color: "#000000",
                  show_name_color: false,
                  show_descr_color: false,
                  id: item.id,
                  openInfo:false,
                },
              ],
            };
            const candidate = this.listings.find(x => x.title === defaultListingGroup.title);
            if (candidate) {
              candidate.content.push(defaultListingGroup.content[0]);
            } else {
              this.listings.push(defaultListingGroup);
            }
          }
        },
        showSearch(){
          this.search = true;
        },
        getDates(startDate, stopDate) {
          let dateArray = [];
          let currentDate = this.$moment(startDate);
          let stopDateMoment = this.$moment(stopDate);
          while (currentDate <= stopDateMoment) {
            dateArray.push(
              {
                start:this.$moment(currentDate).format('YYYY-MM-DD'),
                active:true
              }
            );
            currentDate = this.$moment(currentDate).add(1, 'days');
          }
          const weekArr = [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday'
          ];
          for(let i = 0;i<weekArr.indexOf(this.$moment(startDate).format('dddd'));i++){
            dateArray.unshift('');
          }
          return dateArray;
        },
        clearDates(){
          this.selectedDates = [];
          for(let i = 0;i<this.disabledDates.length;i++){
            const candidate = this.dates.find(x => x.start === this.disabledDates[i].start);
            if(candidate){
              const candidateIndex = this.dates.indexOf(candidate);
              if(candidateIndex >= 0){
                this.dates[candidateIndex].active = true;
              }
            }
          }
          this.disabledDates = [];
          this.updateDates();
        },
        updateDates(){
          const lastDate = +this.$this.$moment(this.dates[this.dates.length-1].start).format('x');
          if(lastDate < +this.$this.$moment().format('x')){
            for(let i = 0;i<this.dates.length;i++){
              if(this.dates[i]){
                this.dates[i].active = false;
              }
            }
          }
          for(let i = 0;i<this.dates.length;i++){
            if(this.dates[i]){
              this.dates[i].ignored = false;
            }
          }
          const startIndex = this.$this.$moment().format('YYYY-MM-DD');
          const candidateDate = this.dates.find(x => x.start === startIndex);
          if(candidateDate){
            const candidateIndex = this.dates.indexOf(candidateDate);
            if(candidateIndex>=0){
              for(let i = 0;i<this.dates.length;i++){
                if(!this.dates[i].active){
                  let indexMinIgnore = i-this.listing_item.min_day_stays+1;
                  if(indexMinIgnore>=0){
                    for(let index = indexMinIgnore;index<i;index++){
                      if(this.dates[index]){
                        this.dates[index].ignored = true;
                      }
                    }
                  }
                }
                if(i<candidateIndex){
                  if(this.dates[i].active){
                    this.dates[i].active = false;
                  }
                }
              }
            }
          }
          if(this.selectedDates.length === 1){
            const candidateIndex = this.dates.find(x => x.start === this.selectedDates[0].start);
            if(candidateIndex){
              const index = this.dates.indexOf(candidateIndex);
              if(index>=0){
                for(let i =0;i<index;i++){
                  if(this.dates[i].active){
                    this.disabledDates.push(this.dates[i]);
                    this.dates[i].active = false;
                  }
                }
                for(let i = index+1;i<(index+this.listing_item.min_day_stays);i++){
                  if(this.dates[i]){
                    this.dates[i].ignored = true;
                    this.ignoredDates.push(this.dates[i]);
                  }
                }
                let NonActiveFound = false;
                let lastIndex = 0;
                for(let i = index+this.listing_item.min_day_stays-1;i<this.dates.length;i++){
                  if(this.dates[i]){
                    if(!this.dates[i].active){
                      NonActiveFound = true;
                      break;
                    }
                    if(this.dates[i].ignored){
                      lastIndex = i+1;
                      this.dates[i].ignored = false;
                    }
                  }
                }
                if(NonActiveFound){
                  for(let i = lastIndex;i<this.dates.length;i++){
                    if(this.dates[i]){
                      if(this.dates[i].active){
                        this.disabledDates.push(this.dates[i]);
                      }
                      this.dates[i].active = false;
                    }
                  }
                }
              }
            }else{
              const candidateStart = this.archiveDates.find(x => x.start === this.selectedDates[0].start);
              if(candidateStart){
                const candidateStartIndex = this.archiveDates.indexOf(candidateStart);
                if(candidateStartIndex>=0){
                  let index = 0;
                  let endIndex = 0;
                  let found = false;
                  for(let i = candidateStartIndex;i<this.archiveDates.length;i++){
                    if(this.archiveDates[i]){
                      index++
                    }
                    if(index === this.listing_item.min_day_stays){
                      found = true;
                      endIndex = i;
                      break;
                    }
                  }
                  if(!found){
                    return
                  }
                  const candidateEnd = this.archiveDates[endIndex];
                  console.log(endIndex);
                  console.log(candidateEnd);
                  const candidateDateEnd = this.dates.find(x => x.start === candidateEnd.start);
                  if(candidateDateEnd){
                    const candidateDateEndIndex = this.dates.indexOf(candidateDateEnd);
                    if(candidateDateEndIndex>=0){
                      for(let i = 0;i<candidateDateEndIndex;i++){
                        if(this.dates[i]){
                          this.dates[i].ignored = true;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        openDetail(groupIndex,index){
          // this.$router.push(`/${this.$route.params.id}/menu/${this.$route.params.page}/estates/${groupIndex}/${index}`)
          this.$store.state.realEstate.previewPage = `detail${this.listings[groupIndex].content[index].id}`;
        },
        openInfo(point){
          for(let i =0;i<this.listings.length;i++){
            for(let index = 0;index<this.listings[i].content.length;index++){
              this.listings[i].content[index].openInfo = false;
            }
          }
          point.openInfo = true
        },
        setDefaultListing(){
          this.bathroomsCount = "Any";
          this.bedsCount = "Any";
          this.bedroomsCount = "Any";
          this.changeDataModel(this.$store.state.realEstate.realEstates);
        },
        changeBathrooms(index){
          if(index){
            this.bathroomsCount = index;
          }else{
            this.bathroomsCount = "Any";
          }
          this.changeDataModel(this.$store.state.realEstate.realEstates);
        },
        changeBeds(index){
          if(index){
            this.bedsCount = index;
          }else{
            this.bedsCount = "Any"
          }
          this.changeDataModel(this.$store.state.realEstate.realEstates);
        },
        changeBedrooms(index){
          if(index){
            this.bedroomsCount = index;
          }else{
            this.bedroomsCount = "Any"
          }
          this.changeDataModel(this.$store.state.realEstate.realEstates);
        },
        incPersonCount(person){
          if(person.name !== 'Adults' && !this.personsTypes[0].count){
            this.personsTypes[0].count = 1;
          }
          person.count = person.count+1;
        },
        checkFavorite(item){
          if(item.favorite){
            this.removeFromFavorite(item)
          }else{
            this.addToFavorite(item);
          }
        },
        addToFavorite(item){
          if(localStorage.getItem(`hotel_${this.$route.params.id}_favorite`)){
            let str = localStorage.getItem(`hotel_${this.$route.params.id}_favorite`);
            str+= ` spaces/${item.id}`;
            localStorage.setItem(`hotel_${this.$route.params.id}_favorite`,str);
          }else{
            const str = `spaces/${item.id}`
            localStorage.setItem(`hotel_${this.$route.params.id}_favorite`,str);
          }
          item.favorite = true;
        },
        removeFromFavorite(item){
          const arr = localStorage.getItem(`hotel_${this.$route.params.id}_favorite`).split(' ');
          const candidateIndex = arr.indexOf(`spaces/${item.id}`);
          if(candidateIndex>=0){
            arr.splice(candidateIndex,1);
            localStorage.setItem(`hotel_${this.$route.params.id}_favorite`,arr.join(' '))
            item.favorite = false;
          }
        },
        decPersonCount(person){
          person.count = person.count-1;
        },
        clickFlex(){
          this.who = false;
          this.flex = true;
        },
        clickWho(){
          this.flex = false;
          this.who = true;
        },
        checkBorderRadius(){
          if(+this.content.styles.lining_width === 100){
            if(this.content.styles.position === 'flex-start flex-start' || this.content.styles.position === 'flex-end flex-start' || this.content.styles.position === 'center flex-start'){
              return `border-top-right-radius:${this.item.styles.borderListingRadius}px;border-top-left-radius:${this.item.styles.borderListingRadius}px;`
            }
            if(this.content.styles.position === 'flex-start flex-end' || this.content.styles.position === 'flex-end flex-end' || this.content.styles.position === 'center flex-end'){
              return `border-bottom-right-radius:${this.item.styles.borderListingRadius}px;border-bottom-left-radius:${this.item.styles.borderListingRadius}px;`
            }
          }else{
            return `border-radius:${
              this.item.styles.borderListingRadius
            }px;`
          }
        },
        alignItem(position) {
          if (position === "center") {
            return "center";
          } else if (position === "top") {
            return "flex-start";
          } else if (position === "bottom") {
            return "flex-end";
          }
        },
        timeNow() {
          this.datenow = this.$moment().format("HH:mm");
          setInterval(() => {
            this.datenow = this.$moment().format("HH:mm");
          }, 1000);
        },
        strip(html) {
          let tmp = document.createElement("DIV");
          tmp.innerHTML = html;
          return tmp.textContent || tmp.innerText;
        },
        displayType(item, listing) {
          if (item.displayType === "horizontal") {
            return "flex-direction:row;align-items:normal;background-color:#fff;";
          } else if (item.displayType === "inside") {
            return `background:url(${listing.main_img}) no-repeat center / cover;
                background-size:100%;
                overflow:hidden;
                text-align:center;
                height:200px;
                border-radius:10px;
                background-position: center center;
                background-repeat:no-repeat;
                display:flex;
                background-color:#fff;
                align-items:${this.content.styles.position.split(' ')[1]};
                justify-content:${this.content.styles.position.split(' ')[0]};`
          } else {
            return "flex-direction:column;background-color:#fff;";
          }
        },
      },
      async mounted() {
        this.timeNow();
        const payloadContent = {
          hotel_id:this.$route.params.id,
          menu_id:this.item.id
        };
        await this.$store.dispatch('realEstate/getRealEstates',payloadContent);
        this.changeDataModel(this.$store.state.realEstate.realEstates);
      }

    }
</script>

<style lang="scss">
    .swiper-pagination > .swiper-pagination-bullet{
        height:10px !important;
        width:10px !important;
    }
</style>
<style lang="scss" scoped>
    /* clear the paddings  */
    ::v-deep .gm-style .gm-style-iw-c {
        padding: 0;
    }
    ::v-deep button.gm-ui-hover-effect {
        visibility: hidden;
    }
    /* remove the white space */
    ::v-deep .gm-style-iw .gm-style-iw-d {
        overflow: auto !important;
    }
    .marker-label{
        font-weight: 800;
        font-size:14px;
        font-family: 'Inter',sans-serif;
    }
    .fade-enter-active {
        animation: bounce-in 0.5s;
    }
    .fade-leave-active {
        animation: bounce-in 0.5s reverse;
    }
    @keyframes bounce-in {
        0% {
            top:100vh;
        }
        100% {
            top:0;
        }
    }
    .fade-list-enter-active {
        animation: bounce-list 0.5s;
    }
    .fade-list-leave-active {
        animation: bounce-list 0.5s reverse;
    }
    @keyframes bounce-list {
        0% {
            height:0;
        }
        100% {
            height:100%;
        }
    }

    .fade-enter-active {
        animation: bounce-in 0.5s;
    }
    .fade-leave-active {
        animation: bounce-in 0.5s reverse;
    }
    @keyframes bounce-in {
        0% {
            top:100vh;
        }
        100% {
            top:0;
        }
    }
    .slide-leave-active,
    .slide-enter-active {
        transition: 0.5s;
    }
    .slide-enter {
        transform: translate(500%, 0);
        position: absolute;
    }
    .slide-leave-to {
        transform: translate(-500%, 0);
        position: absolute;
    }

    .slideback-leave-active,
    .slideback-enter-active {
        transition: 0.5s;
    }
    .slideback-enter {
        transform: translate(-500%, 0);
        position: absolute;
    }
    .slideback-leave-to {
        transform: translate(500%, 0);
        position: absolute;
    }
</style>
